$color_1: #FFFFFF;
$color_2: #000000;
$color_3: #D8F2FF;
$font-family_1: ABlack;
$font-family_2: ABook;
$background-color_1: #000000;

.test{
  .top{
    height: 100vh;
    background: rgb(225, 91, 91);
  }
  .testcenter{
    height: 100vh;
    background: greenyellow;
  }
  .project {
		// padding: 1.48rem 1.74rem 2.12rem;
		// background: url('../../assets/Project-bg.png') no-repeat top center;
		// background-size: cover;
    background-attachment: fixed;
    // padding-left: 3.15rem;
    // padding-right: 1.8rem;
    display: flex;
    position: relative;
    .projectbg{
      width: 100%;
      height: 100%;
      max-height: 100vh;
      background: url('../../assets/Project-bg.png') no-repeat top center;
      background-size: 100% auto;
      position: absolute;
      left: 0;
      top: 0;
    }
		
    .desktopContentSection{
      min-height: 70vh;
      /* outline: 1px solid green; */
     
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      h1{
        font-size: .4rem;
        line-height: 1.2;
        display: none;
      }
      p{
        font-size: .24rem;
        line-height: 1.2;
        display: none;
      }
    }
    .left {
      width:0;
      position: relative;
    }
    
    .right {
      height:100vh;
      /* outline:1px solid purple; */
    
      box-sizing: border-box;
      width:100%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      background: url('../../assets/Project-bg2.png') no-repeat top center;
      background-size: cover;
      position: relative;
      h2 {
        color: $color_1;
        max-width: 10.95rem;
        font-family: $font-family_1;
        font-size: .7rem;
        font-weight: 800;
        line-height: .84rem;
        text-transform: uppercase;
        color: $color_1;
        margin-bottom: 1.35rem;
        position: absolute;
        left: 3.15rem;
        top: 1.65rem;
        z-index: 9;
      }
    }
    
    .desktopContent {
      margin:auto;
      width:80%;
    }
    
    .desktopContentSection {
      min-height:80vh;
      /* outline:1px solid green; */
      display:flex;
      flex-direction:column;
      justify-content:center;
    }
    
    
    .desktopPhotos {
      // width:40vw;
      // height:40vw;
      // border-radius:20px;
      position:relative;
      height: 3.27rem;
      overflow:hidden;
      padding-left: 3.15rem;
      padding-right: 1.8rem;
      box-sizing: border-box;
      // box-shadow:4px 4px 4px rgba(0, 0, 0, 0.4);
    }
    
    .desktopPhoto {
      position:absolute;
      width:100%;
      height:100%;
    }
    
		.project-list {
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			flex-wrap: wrap;
			.item {
				// &:nth-child(3) {
				// 	width: 100%;
				// }
				// width: calc(50% - .14rem);
        height: auto;
        max-width: 14.26rem;
        width: 100%;
        height: 3.27rem;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
				// border-radius: .24rem;
        border-radius: .26rem 1.635rem 1.635rem .26rem;
				background: linear-gradient(114deg, #B8E8FF 43%, #FFFFFF 135%);
				backdrop-filter: blur(65.28px);
				box-shadow: 0px 2px 54px 0px rgba(91, 145, 209, 0.13),inset 0px -1px 3px 0px rgba(54, 131, 221, 0.69),inset 0px 1px 9px 0px rgba(255, 255, 255, 0.5);
				padding-right: 1.57rem;
        // padding-left: 2.9rem;
				overflow: hidden;
				// margin-bottom: .4rem;
				.ord {
					font-family: $font-family_1;
					font-size: 3rem;
					font-weight: 800;
					line-height: 1.2;
					color: $color_1;
					margin-left: -0.9rem;
				}
				.content {
					flex: 1;
					color: $color_1;
					padding: .2rem 0;
					padding-left: .2rem;
					h3 {
						font-family: $font-family_1;
						font-size: .42rem;
						font-weight: 800;
						line-height: 1.2;
						color: $color_2;
						margin-bottom: .37rem;
					}
					p {
						font-family: $font-family_2;
						font-size: .2rem;
						font-weight: normal;
						line-height: 1.2;
						color: $color_2;
					}
				}
			}
		}
	}
  .bottom{
    height: 200vh;
    background: #888;
  }
}