iframe {
  display: none;
}

*{
  padding: 0;
  margin: 0;
}

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  margin: 0;
  background: linear-gradient(0deg, #DCF5FF 0%, #FFFFFF 95%);
}



html{
  --status-bar-height: 0px; 
  --top-window-height: 0px; 
  --window-left: 0px;
   --window-right: 0px; 
   --window-margin: 0px; 
   --window-top: calc(var(--top-window-height)
  + 0px); 
  --window-bottom: calc(50px + env(safe-area-inset-bottom));
}

 @font-face {
  font-family: 'ABook'; 
  src: url('./fonts/Avenir/Avenir-Book-01.ttf') format('opentype');
} 

@font-face {
  font-family: 'ABlack'; 
  src: url('./fonts/Avenir/Avenir-Black-03.ttf') format('opentype');
} 


:where(.css-dev-only-do-not-override-txh9fw).ant-steps .ant-steps-item-wait .ant-steps-item-icon >.ant-steps-icon{
  color: #ffffff;
}

 .ant-select-selector{
  border: none!important;
}


.Toastify__toast-body {
  font-size: 16px;
}

.Toastify__toast-body > div:last-child{
  word-break: break-word;
}