$color_1: #FFFFFF;
$color_2: #000000;
$color_3: #D8F2FF;
$font-family_1: ABlack;
$font-family_2: ABook;
$background-color_1: #000000;

.mpage {
	position: relative;
	width: 100%;
	.header {
		padding: .12rem .286rem;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 99;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.logo {
			display: flex;
			align-items: center;
			img {
				height: .4rem;
			}
		}
		.nav {
			display: flex;
			align-items: center;
			justify-content: center;
			.langu {
				display: flex;
				margin-right: .22rem;
				img {
					width: .2rem;
				}
			}
			.contact {
				font-family: $font-family_1;
				font-size: .16rem;
				font-weight: 900;
				line-height: .19rem;
				padding: .07rem .14rem;
				border-radius: .34rem;
				background: #2C2C2C;
				color: $color_1;
				outline: none;
				border: none;
			}
		}
	}
	.banner {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding-top: 1.43rem;
		padding-bottom: 1.05rem;
		background: url('../../assets/page-bg.png') no-repeat top center;
		background-size: cover;
		.logo {
			width: .78rem;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				left: 50%;
				bottom: 0;
				width: .22rem;
				height: .04rem;
				background-color: $background-color_1;
				border-radius: .2rem;
				transform: translateX(-50%);
			}
		}
		h2 {
			font-family: $font-family_1;
			margin-top: .3rem;
			font-size: .26rem;
			font-weight: 800;
			line-height: .31rem;
			text-align: center;
			color: $color_2;
			width: 100%;
			padding: 0 .3rem;
			box-sizing: border-box;
		}
		.download{
			text-decoration: none;
			font-family: $font-family_1;
			font-size: .16rem;
			font-weight: 900;
			line-height: .19rem;
			padding: .07rem .14rem;
			border-radius: .34rem;
			background: #2C2C2C;
			color: $color_1;
			margin-top: .3rem;
		}
	}
	.project {
		padding: .7rem .2rem;
		background: url('../../assets/mproject-bg.png') no-repeat top center;
		background-size: cover;
		margin-top: -0.3rem;
		.project-list {
			.item {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 13px;
				background: linear-gradient(123deg, #B8E8FF 38%, #FFFFFF 133%);
				backdrop-filter: blur(32.64px);
				box-shadow: 0px 1px 27px 0px rgba(91, 145, 209, 0.13),inset 0px -1px 2px 0px rgba(54, 131, 221, 0.69),inset 0px 1px 5px 0px rgba(255, 255, 255, 0.5);
				overflow: hidden;
				margin-bottom: .4rem;
				padding: .36rem .3rem .42rem;
				position: relative;
				.ord {
					font-size: .9rem;
					font-weight: 800;
					line-height: 1.08rem;
					color: $color_1;
					position: absolute;
					left: -0.23rem;
					top: -0.2823rem;
				}
				.content {
					flex: 1;
					color: $color_1;
					position: relative;
					h3 {
						font-family: $font-family_1;
						font-size: .2rem;
						font-weight: 800;
						line-height: .24rem;
						color: $color_2;
						margin-bottom: .07rem;
					}
					p {
						font-family: $font-family_2;
						font-size: .15rem;
						font-weight: normal;
						line-height: 1.2;
						color: $color_2;
					}
				}
			}
		}
	}
	.moreList {
		padding: 0 .2rem;
		.item {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			padding-top: .7rem;
			position: relative;
			margin-bottom: .16rem;
			.ord {
				font-family: $font-family_1;
				font-size: .9rem;
				font-weight: 800;
				line-height: 1.08rem;
				color: $color_3;
				position: absolute;
				top: 0;
				left: 0;
			}
			.content {
				padding-left: .07rem;
				flex: 1;
				position: relative;
				h3 {
					font-family: $font-family_1;
					font-size: .2rem;
					font-weight: 800;
					line-height: .24rem;
					color: $color_2;
					margin-bottom: .18rem;
				}
				p {
					font-family: $font-family_2;
					font-size: .2rem;
					font-weight: normal;
					line-height: 1.2;
					color: $color_2;
				}
			}
			.img {
				display: flex;
				justify-content: flex-end;
				width: 100%;
				img {
					width: 3.08rem;
				}
			}
		}
	}
	.footer {
		padding: .55rem 0 .19rem;
		background: url('../../assets/m-footer-bg.png') no-repeat top center;
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		.logo {
			height: 1.26rem;
			margin-bottom: .22rem;
		}
		.socialList {
			display: flex;
			align-items: center;
			margin-bottom: .26rem;
			.item {
				padding: 0 .18rem;
				a {
					display: flex;
				}
				img {
					width: .3rem;
				}
			}
		}

		.list{
			display: flex;
			// flex-direction: column;
			.item{
				display: flex;
				img{
					height: .45rem;
				}
				&:first-child{
					margin-right: .1rem;
				}
			}
		}
		.nav {
			display: flex;
			align-items: center;
			.item {
				padding: 0 .16rem;
				font-family: $font-family_2;
				font-size: .12rem;
				font-weight: normal;
				line-height: .14rem;
				text-transform: uppercase;
				text-decoration: underline;
				color: $color_1;
			}
		}
		p {
			font-family: $font-family_2;
			font-size: .12rem;
			font-weight: normal;
			line-height: .14rem;
			color: $color_1;
			margin-top: .4rem;
		}
	}
}
