.listview{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  .header{
    height: 1.13rem;
    border-radius: 0px 0px 56.5px 56.5px;
    background: #2B2B2B;
    display: flex;
    align-items: center;
    padding: 0 2.77rem;
    justify-content: space-between;
    .logo{
      display: flex;
      img{
        height: .7rem;
      }
    }
    .nav{
      margin: 0 -0.46rem;
      display: flex;
      align-items: center;
      a{
        display: block;
        padding: 0 .46rem;
        font-size: .22rem;
        font-weight: normal;
        line-height: .38rem;
        text-decoration: none;
        color: rgba(255, 255, 255, 0.68);
        &.active{
          color: #FFFFFF;
        }
      }
    }
    .wallet{
      button{
        display: flex;
        align-items: center;
        line-height: .34rem;
        font-family: "ABlack";
        background-color: #B1E5FF;
        border: none;
        padding-left: .12rem;
        padding-right: .18rem;
        border-radius: .08rem;
        color: #fff;
        cursor: pointer;
        img{
          height: .1rem;
          margin-right: .1rem;
        }
      }
    }
    .walletInfo{
      .paper{
        width: 42px;
        height: 42px;
      }
      .mantine-focus-auto{
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
      }
      .address{
        display: flex;
        flex-direction: column;
    
        .Disconnect{
          border:none;
          background-color: transparent;
          &:hover,&:focus{
            border:none;
            background-color: transparent;
          }
          font-family: 'ABook';
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
          color: rgba(255, 255, 255, 0.5);
          margin-top: .05rem;
          text-align: left;
          cursor: pointer;
        }
      }
      .mantine-Button-inner{
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
      }
      .mantine-Button-label{
        display: flex;
      }
    }
  }
  .main{
    // flex: 1;
    height: calc(100vh - 1.13rem);
    width: 100%;
    overflow: hidden;
   
    // padding: 0 2.78rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .content{
      max-width: 13.64rem;
      width: 100%;
      height: 100%;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      padding-top: 0.42rem;
      padding-bottom: 1.15rem;
      .search{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: .24rem;
        button{
          // height: .4rem;
          border-radius: 20px;
          opacity: 1;
          background: #2B2B2B;
          color: #FFFFFF;
          font-family: 'ABlack';
          border: none;
          outline: none;
          padding: .16rem .18rem;
          font-size: .2rem;
          font-weight: normal;
          line-height: .24rem;
          margin-right: .07rem;
          border-radius: .8rem;
        }
        .search_box{
          display: flex;
          align-items: center;
          // width: 2.29rem;
          border: 1px solid rgba(43, 43, 43, 0.3);
          background-color: #fff;
          padding: .07rem .11rem;
          border-radius: .7rem;
          .icon-search{
            display: flex;
            align-items: center;
            img{
              width: .27rem;
              height: .27rem;
            }
          }
          input{
            flex: 1;
            height: .4rem;
            border: none;
            outline: none;
            padding: 0 .1rem;
          }
        }
      }
      .listBox{
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        // font-family: ABlack;
        font-size: .2rem;
        border-radius: .3rem;
        font-weight: normal;
        line-height: .24rem;
        padding: .16rem .12rem;
        background: linear-gradient(126deg, #B8E8FF 35%, #FFFFFF 132%);
        box-sizing: border-box;
        .tbheader{
          display: flex;
          justify-content: space-between;
          list-style: none;
          padding: 0 .2rem;
          box-sizing: border-box;
          padding-bottom: .18rem;
          width: 100%;
          li{
            width: 25%;
            text-align: center;
            // font-family: ABlack;
            font-size: .2rem;
            font-weight: normal;
            line-height: .24rem;
          }
        }
        .tbbody{
          flex: 1;
          width: 100%;
          overflow: auto;
          .item{
            padding: .07rem .2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            margin-bottom: .04rem;
            div{
              width: 25%;
              text-align: center;
              &.token{
                display: flex;
                align-items: center;
                .collection{
                  width: .2rem;
                  height: .2rem;
                  margin-right: .2rem;
                }
                .token_img{
                  width: .34rem;
                  height: .34rem;
                  margin-right: .09rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

.wallet_model{
  // --mantine-scale: 1;
  // --mantine-spacing-lg: calc(1.25rem*var(--mantine-scale));
  // --mantine-spacing-sm: calc(0.75rem*var(--mantine-scale));
  // --mantine-spacing-xs: calc(0.625rem*var(--mantine-scale));
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -199999;
  opacity: 0;
  display: none;
  .ConnectWallet_dropdown{
    border: .01rem solid #e9ecef;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px 0px, rgba(0, 0, 0, 0.05) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    border-radius: .08rem;
    position: absolute;
    min-width: 320px;
    transition-property: opacity;
    transition-duration: 150ms;
    transition-timing-function: ease;
    opacity: 1;
    z-index: 300;
    top: 1.13rem;
    right: 2.77rem;
    width: max-content;
    padding-top: .16rem;
    background-color: #fff;
    .mantine-Title-root{
      font-size: .18rem;
      display: inline-block;
    }
    >div{
      max-width: 5.3rem;
    }
    
    .MoonChainButton_button__181Bc {
      &:not([data-disabled]):hover{
        background-color: #f8f9fa;
      }
      &.MoonChainSection_root__JetMm{
        width: 212px;
        height: 42px;
      }
      display: block;
      color: #25262b;
      border: 1px solid #dee2e6;
      height: 54px;
      border-radius: 8px;
      width: 100%;
      padding-left: 12px;
      padding-right: 10px;
      background-color: #fff;
      >.mantine-Group-root{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 16px 16px;
        justify-content: space-between;
      }
    }
  
    .MoonChainButton_selected__mFVtD{
      border: 1px solid #86dad9;
    }
    .tabcontent{
      padding: .2rem;
      min-height: 240px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: space-between;
    }
    .WalletButton_button__8SwN_{
      height: 42px;
      width: 212px;
      background-color: rgba(134, 218, 217, 0.1);
      border: none;
      border-radius: 8px;
      color: #000;
      padding-left: 12px;
      padding-right: 18px;
      cursor: pointer;
      &:hover{
        background-color: rgb(248, 249, 250);
      }
      &.WalletButton_selected__AKZ36 {
        border: 1px solid #86dad9;
      }
    }
    
    .m-80f1301b, .m-811560b9 {
      align-items: center;
      display: flex;
      height: 100%;
    }
    .m-a74036a{
      margin-right: .1rem;
    }
    .account_box{
      margin-top: 16px;
    }
    .m-b6d8b162 {
      color: rgb(37, 38, 43);
      font-size: .14rem;
      font-weight: 400;
      line-height: 1.2;
      margin: 0;
      padding: 0;
      text-decoration: none;
    }
    .m-4081bf90 {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: space-between;
    }
    .InputlikeText_group__cQxF2 {
      border: 1px solid #dee2e6;
      border-radius: 8px;
      padding: 4px;
      background-color: #fff;
      padding-left: .12rem;
      padding-right: .12rem;
      box-sizing: border-box;
      min-height: 34px;
    }
  }
}



/* 蒙版层样式 */
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* 半透明黑色背景 */
  z-index: 9999; /* 设置层级，确保在最顶层显示 */
  align-items: center;
  justify-content: center;

  &.active {
    display: flex;
  }
}

.import-container {
  width: 6.28rem;
  height: 5.33rem;
  border-radius: 0.34rem;
  background-color: #ecf9ff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  padding: 0.22rem 0.42rem;

  &-title {
    font-size: 0.32rem;
    font-family: 'ABlack';
    line-height: 0.44rem;
    color: #000;
    margin-bottom: 0.39rem;
  }

  &-selecter {
    width: 1.365rem;
    height: 0.58rem;
    border-radius: 0.24rem;
    background-color: #fff;
    overflow: hidden;
    position: relative;
    margin-bottom: 0.25rem;

    &-subscript {
      position: absolute;
      top: 0;
      height: 0.58rem;
      border-radius: 0.24rem;
      background-color: #dcf3ff;
      z-index: 0;
      transition: all linear 0.2s;

      &.subscript-left {
        width: 1.54rem;
        left: 0;
      }

      &.subscript-right {
        width: 1.24rem;
        left: 1.54rem;
      }
    }

    &-wrap {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0 0.14rem;
      font-size: 0.24rem;
      font-family: 'ABlack';
      line-height: 0.33rem;
      color: #85d5fe;
      cursor: pointer;

      &.active {
        color: #000;
      }

      &:nth-child(2) {
        padding: 0 0.4rem;
      }
    }
  }

  &-input {
    &-title {
      font-size: 0.2rem;
      font-family: 'ABlack';
      line-height: 0.27rem;
      color: #000;
      margin-bottom: 0.1rem;
    }

    &-wrap {
      width: 100%;
      height: 0.5rem;
      margin-bottom: 0.25rem;
    }

    &-item {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 0.2rem;
      background-color: #fff;
      outline-style: none;
      font-size: 0.2rem;
      border: none;
      padding: 0 0.2rem;
      margin: 0;
      box-sizing: border-box;
      font-family: 'ABlack';
    }
  }

  &-btn {
    width: 1.87rem;
    height: 0.48rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.2rem;
    color: #fff;
    background-color: #2b2b2b;
    border-radius: 0.2rem;
    margin: 0.43rem auto 0;
    font-family: 'ABlack';
    cursor: pointer;
  }
}
