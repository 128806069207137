$color_1: #FFFFFF;
$color_2: #000000;
$color_3: #D8F2FF;
$font-family_1: ABlack;
$font-family_2: ABook;
$background-color_1: #000000;

.page {
	position: relative;
	width: 100%;
	overflow: hidden;
	background: url('../../assets/page-bg.png') no-repeat top center;
	background-size: contain;
	.header {
		padding: .27rem 1.7rem;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 99;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.logo {
			display: flex;
			align-items: center;
			img {
				height: .7rem;
			}
		}
		.nav {
			display: flex;
			align-items: center;
			justify-content: center;
			.langu {
				display: flex;
				margin-right: .42rem;
				img {
					width: .42rem;
				}
			}
			.contact {
				font-family: $font-family_1;
				font-size: .32rem;
				font-weight: 900;
				line-height: .38rem;
				padding: .16rem .49rem;
				border-radius: .34rem;
				background: #2C2C2C;
				color: $color_1;
				outline: none;
				border: none;
				cursor: pointer;
			}
		}
	}
	.banner {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding-top: 2.72rem;
		padding-bottom: 1.9rem;
		.logo {
			width: 1.25rem;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				left: 50%;
				bottom: 0;
				width: .34rem;
				height: .1rem;
				background-color: $background-color_1;
				border-radius: .2rem;
				transform: translateX(-50%);
			}
		}
		h2 {
			font-family: $font-family_1;
			margin-top: .835rem;
			font-size: .7rem;
			font-weight: 800;
			line-height: .84rem;
			text-align: center;
			color: $color_2;
			max-width: 10.13rem;
			width: 100%;
			// padding: 0 .2rem;
		}
		.download{
			text-decoration: none;
			font-family: $font-family_1;
			font-size: .32rem;
			font-weight: 900;
			line-height: .38rem;
			padding: .16rem .49rem;
			border-radius: .34rem;
			background: #2C2C2C;
			color: $color_1;
			margin-top: .3rem;
		}
	}
	// .project {
	// 	padding: 1.48rem 1.74rem 2.12rem;
	// 	background: url('../../assets/Project-bg.png') no-repeat top center;
	// 	background-size: cover;
	// 	h2 {
	// 		color: $color_1;
	// 		max-width: 10.95rem;
	// 		font-family: $font-family_1;
	// 		font-size: .7rem;
	// 		font-weight: 800;
	// 		line-height: .84rem;
	// 		text-transform: uppercase;
	// 		color: $color_1;
	// 		margin-bottom: 1.35rem;
	// 	}
	// 	.project-list {
	// 		display: flex;
	// 		justify-content: space-between;
	// 		align-items: stretch;
	// 		flex-wrap: wrap;
	// 		.item {
	// 			&:nth-child(3) {
	// 				width: 100%;
	// 			}
	// 			width: calc(50% - .14rem);
	// 			box-sizing: border-box;
	// 			display: flex;
	// 			align-items: center;
	// 			justify-content: center;
	// 			border-radius: .24rem;
	// 			background: linear-gradient(114deg, #B8E8FF 43%, #FFFFFF 135%);
	// 			backdrop-filter: blur(65.28px);
	// 			box-shadow: 0px 2px 54px 0px rgba(91, 145, 209, 0.13),inset 0px -1px 3px 0px rgba(54, 131, 221, 0.69),inset 0px 1px 9px 0px rgba(255, 255, 255, 0.5);
	// 			padding-right: .26rem;
	// 			overflow: hidden;
	// 			margin-bottom: .4rem;
	// 			.ord {
	// 				font-family: $font-family_1;
	// 				font-size: 2rem;
	// 				font-weight: 800;
	// 				line-height: 1.2;
	// 				color: $color_1;
	// 				margin-left: -0.62rem;
	// 			}
	// 			.content {
	// 				flex: 1;
	// 				color: $color_1;
	// 				padding: .2rem 0;
	// 				padding-left: .2rem;
	// 				h3 {
	// 					font-family: $font-family_1;
	// 					font-size: .42rem;
	// 					font-weight: 800;
	// 					line-height: 1.2;
	// 					color: $color_2;
	// 					margin-bottom: .37rem;
	// 				}
	// 				p {
	// 					font-family: $font-family_2;
	// 					font-size: .2rem;
	// 					font-weight: normal;
	// 					line-height: 1.2;
	// 					color: $color_2;
	// 				}
	// 			}
	// 		}
	// 	}
	// }
	.project {
		// padding: 1.48rem 1.74rem 2.12rem;
		// background: url('../../assets/Project-bg.png') no-repeat top center;
		// background-size: cover;
    background-attachment: fixed;
    // padding-left: 3.15rem;
    // padding-right: 1.8rem;
    display: flex;
    position: relative;
    .projectbg{
      width: 100%;
      height: 100%;
      max-height: 100vh;
      background: url('../../assets/Project-bg.png') no-repeat top center;
      background-size: 100% auto;
      position: absolute;
      left: 0;
      top: 0;
    }
		
    .desktopContentSection{
      min-height: 100vh;
      /* outline: 1px solid green; */
			&.item5{
				// min-height: 200vh;
			}
     
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      h1{
        font-size: .4rem;
        line-height: 1.2;
        display: none;
      }
      p{
        font-size: .24rem;
        line-height: 1.2;
        display: none;
      }
    }
    .left {
      width:0;
      position: relative;
    }
    
    .right {
      height:100vh;
      /* outline:1px solid purple; */
    
      box-sizing: border-box;
      width:100%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      background: url('../../assets/Project-bg2.png') no-repeat top center;
      background-size: cover;
      position: relative;
      h2 {
        color: $color_1;
        max-width: 10.95rem;
        font-family: $font-family_1;
        font-size: .7rem;
        font-weight: 800;
        line-height: .84rem;
        text-transform: uppercase;
        color: $color_1;
        margin-bottom: 1.35rem;
        position: absolute;
        left: 3.15rem;
        top: 1.65rem;
        z-index: 9;
				max-width: 6.38rem;
				width: 100%;
      }
			.ordList{
				position: absolute;
				left: 1.72rem;
				top: 50%;
				transform: translateY(-50%);
				list-style: none;
				li{
					width: .18rem;
					height: .18rem;
					border-radius: 50%;
					background: rgba(255, 255, 255, 0.36);
					margin: .47rem 0;
					transition: all linear .3s;
					&.active{
						background: #94DEFF;
					}
				}
			}
    }
    
    .desktopContent {
      margin:auto;
      width:80%;
    }
    
    .desktopContentSection {
      min-height:100vh;
      /* outline:1px solid green; */
      display:flex;
      flex-direction:column;
      justify-content:center;
    }
    
    
    .desktopPhotos {
      // width:40vw;
      // height:40vw;
      // border-radius:20px;
      position:relative;
      height: 3.27rem;
			margin-top: .8rem;
      overflow:hidden;
      padding-left: 3.15rem;
      padding-right: 1.8rem;
      box-sizing: border-box;
      // box-shadow:4px 4px 4px rgba(0, 0, 0, 0.4);
    }
    
    .desktopPhoto {
      position:absolute;
      width:100%;
      height:100%;
    }
    
		.project-list {
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			flex-wrap: wrap;
			.item {
				// &:nth-child(3) {
				// 	width: 100%;
				// }
				// width: calc(50% - .14rem);
        height: auto;
        max-width: 14.26rem;
        width: 100%;
        height: 3.27rem;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
				// border-radius: .24rem;
        border-radius: .26rem 1.635rem 1.635rem .26rem;
				background: linear-gradient(114deg, #B8E8FF 43%, #FFFFFF 135%);
				backdrop-filter: blur(65.28px);
				box-shadow: 0px 2px 54px 0px rgba(91, 145, 209, 0.13),inset 0px -1px 3px 0px rgba(54, 131, 221, 0.69),inset 0px 1px 9px 0px rgba(255, 255, 255, 0.5);
				padding-right: 1.57rem;
        // padding-left: 2.9rem;
				overflow: hidden;
				// margin-bottom: .4rem;
				.ord {
					font-family: $font-family_1;
					font-size: 3rem;
					font-weight: 800;
					line-height: 1.2;
					color: $color_1;
					margin-left: -0.9rem;
				}
				.content {
					flex: 1;
					color: $color_1;
					padding: .2rem 0;
					padding-left: .2rem;
					h3 {
						font-family: $font-family_1;
						font-size: .42rem;
						font-weight: 800;
						line-height: 1.2;
						color: $color_2;
						margin-bottom: .37rem;
					}
					p {
						font-family: $font-family_2;
						font-size: .2rem;
						font-weight: normal;
						line-height: 1.2;
						color: $color_2;
					}
				}
			}
		}
	}
	.moreList {
		padding: 0 1.6rem 0 1.85rem;
		.item {
			display: flex;
			align-items: flex-start;
			padding-top: .47rem;
			padding-bottom: .78rem;
			position: relative;
			margin-bottom: .74rem;
			.bg {
				position: absolute;
				right: 0;
				top: 0;
				width: 4rem;
			}
			.ord {
				font-family: $font-family_1;
				font-size: 2rem;
				font-weight: 800;
				line-height: 2.4rem;
				color: $color_3;
			}
			.content {
				padding-left: .07rem;
				flex: 1;
				h3 {
					font-family: $font-family_1;
					font-size: .44rem;
					font-weight: 800;
					line-height: .53rem;
					color: $color_2;
					margin: .5rem 0;
				}
				p {
					font-family: $font-family_2;
					font-size: .2rem;
					font-weight: normal;
					line-height: 1.2;
					color: $color_2;
				}
			}
			.img {
				width: 3.98rem;
				width: 4.76rem;
			}
		}
		.item.before {
			.bg {
				left: 0;
			}
		}
	}
	.footer {
		padding: 1.2rem 0 .37rem;
		background: url('../../assets/footer-bg.png') no-repeat top center;
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		.content{
			display: flex;
			padding: 0 3rem;
			justify-content: space-around;
			align-items: center;
			width: 100%;
			.left{
				display: flex;
				align-items: center;
			}
			.right{
				p{
					font-size: .2rem;
					text-transform: uppercase;
				}
				.list{
					display: flex;
					.item{
						img{
							height: .71rem;
						}
						&:first-child{
							margin-right: .2rem;
						}
					}
				}
			}

		}
		.logo {
			height: 2.62rem;
		}
		.socialList {
			display: flex;
			align-items: center;
			margin-bottom: .2rem;
			.item {
				padding: 0 .24rem;
				a {
					display: flex;
				}
				img {
					width: .4rem;
				}
			}
		}
		.nav {
			display: flex;
			align-items: center;
			.item {
				padding: 0 .16rem;
				font-family: $font-family_2;
				font-size: .2rem;
				font-weight: normal;
				line-height: .24rem;
				text-transform: uppercase;
				text-decoration: underline;
				color: $color_1;
			}
		}
		p {
			font-family: $font-family_2;
			font-size: .2rem;
			font-weight: normal;
			line-height: .24rem;
			color: $color_1;
			margin-top: .4rem;
		}
	}
}





// .gallery h1 {
// 	font-size:clamp(2em, 4vw, 6em);
// }

// .gallery p {
// 	font-size:clamp(1.4em, 2.5vw, 3.5em);
// 	line-height:1.4;
// }


// .gallery {
//   display:flex;	
//   /* outline:1px solid red; */
// }

// .left {
// 	width:50%;
// }

// .right {
// 	height:100vh;
// 	/* outline:1px solid purple; */
// 	width:50%;
// 	display:flex;
// 	flex-direction:column;
// 	justify-content:center;
// }

// .desktopContent {
// 	margin:auto;
// 	width:80%;
// }

// .desktopContentSection {
// 	min-height:100vh;
// 	/* outline:1px solid green; */
// 	display:flex;
// 	flex-direction:column;
// 	justify-content:center;
// }


// .desktopPhotos {
// 	width:40vw;
// 	height:40vw;
// 	border-radius:20px;
// 	position:relative;
// 	// overflow:hidden;
// 	box-shadow:4px 4px 4px rgba(0, 0, 0, 0.4);
// }

// .desktopPhoto {
// 	position:absolute;
// 	width:100%;
// 	height:100%;
// }




// .red {
// 	background: crimson;
// }

// .green {
// 	background:MediumSeaGreen;
// }

// .blue {
// 	background:dodgerblue;
// }

// .pink{
// 	background:deepPink;
// }


// /* small screen / mobile layout */
// .mobileContent {
// 	display:none;
// 	width:80vw;
// }

// .mobilePhoto {
// 	width:80vw;
// 	height:80vw;
// 	margin-top:5em;
// 	border-radius:6vw;
// }






// /* defines styles for screens up to 599px wide */
// @media screen and (max-width: 599px) {
// 	.left {
// 		display:none;
// 	}
	
// 	.right {
// 		height:auto;
// 		width:100%;
// 		align-items:center;	
// 	}	
	
// 	.desktopPhotos {
// 		display:none;
// 	}
	
// 	.mobileContent {
// 		display:block;
// 	}
	

// }

