.bridgesView{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  .header{
    height: 1.13rem;
    border-radius: 0px 0px 56.5px 56.5px;
    background: #2B2B2B;
    display: flex;
    align-items: center;
    padding: 0 2.77rem;
    justify-content: space-between;
    .logo{
      display: flex;
      img{
        height: .7rem;
      }
    }
    .nav{
      margin: 0 -0.46rem;
      display: flex;
      align-items: center;
      a{
        display: block;
        padding: 0 .46rem;
        font-size: .22rem;
        font-weight: normal;
        line-height: .38rem;
        text-decoration: none;
        color: rgba(255, 255, 255, 0.68);
        &.active{
          color: #FFFFFF;
        }
      }
    }
    .wallet{
      button{
        display: flex;
        align-items: center;
        line-height: .34rem;
        font-family: "ABlack";
        background-color: #B1E5FF;
        border: none;
        padding-left: .12rem;
        padding-right: .18rem;
        border-radius: .08rem;
        color: #fff;
        cursor: pointer;
        img{
          height: .2rem;
          margin-right: .1rem;
        }
      }
    }
  }
  .main{
    // flex: 1;
    height: calc(100vh - 1.13rem);
    width: 100%;
    overflow: scroll;
   
    // padding: 0 2.78rem;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    .content{
      max-width: 13.64rem;
      width: 100%;
      height: 100%;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      padding-top: .4rem;
      padding-bottom: .25rem;
      .evm_content{
        .mystep{
          .ant-steps-item-active{
            .ant-steps-item-icon{
              background: #2B2B2B;
            }
          }
        }

      }
      .mystep{
        // height: 100%;
        padding: 0 .7rem;
        margin-bottom: .6rem;
        .ant-steps-item-active{
          .ant-steps-item-icon{
            background: #B1E5FF;
           
          }
        }
        .ant-steps-icon{
          color: #fff;
        }
        .ant-steps-item-title{
          font-family: 'ABlack';
          font-size: .24rem;
          line-height: .29rem;
          color: #000000;
          &::after{
            left: 50%;
            height: .02rem;
            background-color: #979797;
            top: 12%;
          }
          .ant-steps-item-subtitle{
            display: block;
            max-width: 2.2rem;
            font-family: 'Abook';
            font-size: .18rem;
            line-height: .22rem;
            color: #000000;
            margin-top: .13rem;
            margin-left: 0;
            white-space: break-spaces;
          }
        }
        .ant-steps-item-icon{
          width: .9rem;
          height: .9rem;
          border-radius: 2rem;
          line-height: .9rem;
          font-size: .6rem;
          background-color: #EAEAEA;
          color: #fff!important;
          border: none;
          margin-inline-end: .14rem;
        }
      }
      .stepContent{
        flex: 1;
        overflow: scroll;
        display: flex;
        align-items: center;
        .step1{
          max-width: 13.64rem;
          // max-height: 5.02rem;
          width: 100%;
          background: linear-gradient(116deg, #B8E8FF 42%, #FFFFFF 135%);
          transition: all linear .3s;
          border-radius: 15px;
          padding: .3rem .3rem;
          .item{
            padding: .1rem .23rem;
            border-radius: 8px;
            // opacity: 0.72;

            background: rgba(255, 255, 255, 0.5);
          }
          .send{
            border-radius: 20px;
            margin-top: .4rem;
            background: #2B2B2B;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            &.loading{
              background-color: #fff;
            }
            .loading-icon{
              display: flex;
              align-items: center;
              justify-content: center;
            }
            &:disabled {
              color: #fff;
              background-color:#2b2b2b65;
            }
          }
        }
        .step2{
          max-width: 13.64rem;
          // max-height: 5.02rem;
          width: 100%;
          background: linear-gradient(116deg, #B8E8FF 42%, #FFFFFF 135%);
          transition: all linear .3s;
          border-radius: 15px;
          padding: .26rem .16rem;
          padding-top: 0;
          .container{
            .top{
              display: flex;
              align-items: center;
              justify-content: center;
              .content{
                width: 654px;
                height: 77px;
                padding: 0;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: center;
                font-family: 'ABlack';
                font-size: 20px;
                font-weight: normal;
                line-height: 24px;
                letter-spacing: 0px;

                color: #000000;
                background: url(../../assets/transmit-bg.png) no-repeat center bottom;
                background-size: contain;
              }
            }
            .center{
              padding: .26rem 1.88rem .2rem;
              font-family: 'ABook';
              font-size: .18rem;
              line-height: 1.22;
              color: #000000;
              .gutter{
                display: grid;
                gap: 16px;
                grid-template-columns: repeat(3, calc(33.33% - 14px));
                align-items: stretch;
                

                .gutter-row{
                  background: rgba(255, 255, 255, 0.6905);
                  border-radius: .16rem;
                  padding: 16px;
                  .amount, .add{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  }
                  .time-box{
                    display: flex;
                    justify-content: space-between;
                  }
                  .walletList{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .item{
                      width: 39px;
                      height: 39px;
                      border-radius: 200px;
                      // background: #D8D8D8;
                    }
                    .add-btn{
                      width: 19px;
                      height: 19px;
                      margin-left: .34rem;
                    }
                  }
                }
              }
              .info{
                font-family: 'ABlack';
                font-size: .2rem;
                font-weight: normal;
                line-height: 1.2;
                color: #000000;
                margin-bottom: .1rem;
                .lable-box{
                  margin: .2rem 0;
                  display: flex;
                  align-items: center;
                }
                .network{
                  width: 30%;
                }
                .Status{
                  width: 15%;
                }
                .loading-icon{
                  width: 5%;
                }
                .Timer{
                  text-align: center;
                }
                .Timer,.gas{
                  width: 25%;
                }

                .content-box{
                  font-family: 'ABook';
                  font-size: .18rem;
                  font-weight: normal;
                  line-height: 1.2;
                  color: #000000;
                  padding: .16rem;
                  background: rgba(255, 255, 255, 0.6905);
                  border-radius: .16rem;
                  display: flex;
                  align-items: center;
                  .network{
                    .icon{
                      display: flex;
                      align-items: center;
                      margin-bottom: .06rem;
                      img{
                        margin-right: .1rem;
                      }
                    }
                    .address{
                      display: flex;
                      align-items: center;
                      .from{
                        margin-right: .07rem;
                      }
                    }
                  }
                  .Timer{
                    text-align: center;
                  }
                }
                .Recipient-info{
                  margin-top: .16rem;
                }
              }
              
            }
            .lable{
              font-family: 'ABlack';
              font-size: .2rem;
              font-weight: normal;
              line-height: 1.2;
              color: #000000;
              margin-bottom: .1rem;
            }
          }
        }
      }
    }
  }
}

.myselect{
  .ant-select-selection-item{
    display: flex;
    align-items: center;
    img{
      height: .3rem!important;
    }
  }
}

.ant-select-item-option-content{
  display: flex;
  align-items: center;
}

.wallet_model{
  // --mantine-scale: 1;
  // --mantine-spacing-lg: calc(1.25rem*var(--mantine-scale));
  // --mantine-spacing-sm: calc(0.75rem*var(--mantine-scale));
  // --mantine-spacing-xs: calc(0.625rem*var(--mantine-scale));
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -199999;
  opacity: 0;
  display: none;
  .ConnectWallet_dropdown{
    border: .01rem solid #e9ecef;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px 0px, rgba(0, 0, 0, 0.05) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    border-radius: .08rem;
    position: absolute;
    min-width: 320px;
    transition-property: opacity;
    transition-duration: 150ms;
    transition-timing-function: ease;
    opacity: 1;
    z-index: 300;
    top: 1.13rem;
    right: 2.77rem;
    width: max-content;
    padding-top: .16rem;
    background-color: #fff;
    .mantine-Title-root{
      font-size: .18rem;
      display: inline-block;
    }
    >div{
      max-width: 5.3rem;
    }
    
    .MoonChainButton_button__181Bc {
      &:not([data-disabled]):hover{
        background-color: #f8f9fa;
      }
      &.MoonChainSection_root__JetMm{
        width: 212px;
        height: 42px;
      }
      display: block;
      color: #25262b;
      border: 1px solid #dee2e6;
      height: 54px;
      border-radius: 8px;
      width: 100%;
      padding-left: 12px;
      padding-right: 10px;
      background-color: #fff;
      >.mantine-Group-root{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 16px 16px;
        justify-content: space-between;
      }
    }
  
    .MoonChainButton_selected__mFVtD{
      border: 1px solid #86dad9;
    }
    .tabcontent{
      padding: .2rem;
      min-height: 240px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: space-between;
    }
    .WalletButton_button__8SwN_{
      height: 42px;
      width: 212px;
      background-color: rgba(134, 218, 217, 0.1);
      border: none;
      border-radius: 8px;
      color: #000;
      padding-left: 12px;
      padding-right: 18px;
      cursor: pointer;
      &:hover{
        background-color: rgb(248, 249, 250);
      }
      &.WalletButton_selected__AKZ36 {
        border: 1px solid #86dad9;
      }
    }
    
    .m-80f1301b, .m-811560b9 {
      align-items: center;
      display: flex;
      height: 100%;
    }
    .m-a74036a{
      margin-right: .1rem;
    }
    .account_box{
      margin-top: 16px;
    }
    .m-b6d8b162 {
      color: rgb(37, 38, 43);
      font-size: .14rem;
      font-weight: 400;
      line-height: 1.2;
      margin: 0;
      padding: 0;
      text-decoration: none;
    }
    .m-4081bf90 {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: space-between;
    }
    .InputlikeText_group__cQxF2 {
      border: 1px solid #dee2e6;
      border-radius: 8px;
      padding: 4px;
      background-color: #fff;
      padding-left: .12rem;
      padding-right: .12rem;
      box-sizing: border-box;
      min-height: 34px;
    }
  }
}


.mantine-Paper-root{
  background-color: #fff;
  border: none;
  border-radius: 8px;
  // box-shadow: var(--paper-shadow,none);
  display: block;
  outline: 0;
  text-decoration: none;
  touch-action: manipulation;
  padding-right: .2rem;
  padding-left: .2rem;
  padding-bottom: .12rem;
  font-size: .16rem;
  font-weight: 700;
  line-height: 1.45;
  margin: 0;

  .icon{
    margin-left: .1rem; display: inline-block;
  }
  >.mantine-Group-root{
    margin-bottom: 16px;
  }
 }
.mantine-Group-root{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px 16px;
  justify-content: flex-start;
}

.paper{
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin: 0px;
  overflow: hidden;
  padding: 0px;
  background-color: rgb(21, 206, 242);
  height: 24px;
  width: 24px;
}

.ant-popover {
  width: calc( 5.3rem - .4rem);
  .ant-popover-arrow{
    display: none;
  }
  .paper{
    width: 36px;
    height: 36px;
    margin-right: 20px;
  }
  .address{
    font-size: 12px;
    color: rgb(37, 38, 43);
  }
  .name{
    font-size: .14rem;
    font-family: 'ABlack';
    color: rgb(37, 38, 43);
  }
}

.select_address{
  border-radius: 8px;
  background: #FFFFFF;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
  width: 100%;
  box-sizing: border-box;
}

.from_collectEVMWallet,.from_collectSubMWallet{
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;


  .wallet{
    button{
      display: flex;
      align-items: center;
      line-height: .34rem;
      font-family: "ABlack";
      background-color: #B1E5FF;
      border: none;
      padding-left: .12rem;
      padding-right: .18rem;
      border-radius: .08rem;
      color: #fff;
      cursor: pointer;
      img{
        height: .2rem;
        margin-right: .1rem;
      }
    }
  }
}

.select_address{
  button{
    border: none;
    box-shadow: none;
    padding: 4px 0;
  }
}

.m-4081bf90{
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px 16px;
  justify-content: flex-start;
  width: 100%;
  p{
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    text-align: left;
  }
}

.m-3eebeb36{
  border-left: 1px solid #141517;
  margin: 4px 0;
  align-self: stretch;
  height: auto;
}

.m-8fb7ebe7{
  appearance: none;
  background-color: #f1f3f5;
  border: 1px solid transparent;
  border-radius: 8px 0 0 8px;
  color: #000;
  cursor: text;
  display: block;
  font-size: 18px;
  height: 50px;
  line-height: 48px;
  min-height: 50px;
  overflow: visible;
  padding: 0 17px;
  resize: none;
  text-align: left;
  transition: border-color .1s ease;
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.m-8fb7ebe7:disabled, .m-8fb7ebe7[data-disabled] {
  background-color: #f1f3f5;
  color: #868e96;
  cursor: not-allowed;
  opacity: .6;
}

.m-77c9d27d{
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  text-decoration: none;
  touch-action: manipulation;
  position: relative;
  text-align: center;
  user-select: none;
  width: 30px;
  cursor: not-allowed;
  display: block;
  font-size: 12px;
  font-weight: 600;
  background:rgb(134, 218, 217);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 32px;
}




.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(.3);
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #000000;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.mb20{
  margin-bottom: .2rem;
}

.Finish{
  margin-right: .15rem;
}

.Bridge-again{
  border-radius: 20px;
  background: #2B2B2B;
  &:disabled {
    color: #fff;
    background-color:#2b2b2b65;
  }
}


.converTabs{
  .ant-tabs-nav{
    margin-bottom: .6rem;
    &::before{
      border: none;
    }
    .ant-tabs-nav-list{
      background: #F6F6F6;
      border-radius: 16px 16px 0 0 ;
    }
    .ant-tabs-tab{
      font-size: .2rem;
      font-weight: normal;
      line-height: 1.2;
      color: #666666;
      padding: .15rem;
      margin: 0!important;
      border: none;
      background-color: transparent;
      &.ant-tabs-tab-active{
        background-color: #000;
        border-radius: 16px 16px 0 0 ;
        .ant-tabs-tab-btn{
          color: #fff;
        }
      }
    }
  }
}


.bindmodel{
  width: auto!important;
  .content{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: .3rem 0;
    .bind-icon{
      margin: 0 16px;
    }
    .item{
      flex: 1;
      border-radius: 34px;
      opacity: 1;
      min-width: 312px;
      background: #F6F6F6;
      font-size: 24px;
      font-weight: normal;
      line-height: 29px;
      color: #000000;
      display: flex;
      align-items: center;
      padding: 9px 12px;
      justify-content: center;
      &.addres{
        justify-content: flex-start;
      }
      .paper{
        width: .5rem;
        height: .5rem;
        margin-right: .12rem;
      }
    }
  }
  .confirm{
    display: flex;
    justify-content: flex-end;
    button{
      padding: 10px 30px;
      color: #fff;
      background-color: #000;
      border-radius: 200px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      &:disabled{
        color: #fff;
        background-color: rgba(43, 43, 43, 0.3960784314);
      }

      &.loading{
        background-color: rgb(151, 151, 151);
      }
      .loading-icon{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}





.model-evm{
  .bridgesView .main .content .stepContent .step1,.bridgesView .main .content .stepContent .step2{
    background: linear-gradient(116deg, #DFDFDF 42%, #FFFFFF 135%);
  }
}




.custom-notification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* 使通知显示在最上层 */
  background-color: rgba(0, 0, 0, 0.6); /* 添加蒙层背景 */
  color: #fff; /* 设置文本颜色 */
}